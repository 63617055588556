/**
 * Created by dev on 26.05.17.
 *
 * example:
 * argilla.getElementByName('test[1]');
 *
 */

window.argilla = function() {
  return this;
};

argilla.debug = false;

argilla.parameters = {};

argilla.calbackList = {};

argilla.timers = {};

/**
 * отложенные вызовы для callback
 * @type {null}
 */
argilla._callbackDeferred = null;

argilla.setDebugMode= function(mode) {
  argilla.debug = mode;

  if( argilla.debug )
    console.log('Режим отладки включен');
  else
    console.log('Режим отладки выключен');
};

argilla.message = function(message) {
  if( argilla.debug )
    console.log(message);
};

argilla.setParam = function(name, value) {
  argilla.parameters[name] = value;
};

argilla.getParam = function(name) {
  return argilla.parameters[name];
};

argilla.errorMessage = function(errorMessage) {
  alert(errorMessage);
};

argilla.error = function(error) {
  console.log(error);
};

/**
 * Создает объект Deferred для callback
 * @returns {null|Deferred}
 */
argilla.createCallbackDeferred = function() {
  if( argilla._callbackDeferred === null )
    argilla._callbackDeferred = $.Deferred();

  return argilla._callbackDeferred;
};

argilla.call = function(data) {
  for(var i in data)
  {
    if( argilla.calbackList && argilla.calbackList.i !== undefined )
    {
      argilla.calbackList[i](data[i]);
    }
  }
};

argilla.checkResponse = function(response, form) {
  if( response === undefined || response === null )
    return;

  if( response.status == 'ok' )
  {
/*    if( response.event !== undefined ) {
      argilla.event.raise(response.event.eventName, response.event.data);
    }*/

    if( response.events !== undefined ) {
      for(var i in response.events)
      {
        if( !response.events.hasOwnProperty(i) )
          continue;

        argilla.event.raise(response.events[i].eventName, response.events[i].data);
      }
    }

    if( response.updateElements !== undefined )
      argilla.dom.updateElements(response.updateElements);

    if( response.hideElements !== undefined )
      argilla.dom.hideElements(response.hideElements);

    if( response.showElements !== undefined )
      argilla.dom.showElements(response.showElements);

    if( response.removeElements !== undefined )
      argilla.dom.removeElements(response.removeElements);

    if( response.overlayLoader !== undefined )
      $.overlayLoader(true, response.overlayLoader);

    var resolveAfterCallbacks = function() {
      if( response.reload !== undefined )
        location.reload();

      if( response.redirect !== undefined )
        location.href = response.redirect;

      if( response.message !== undefined )
        alert(response.message);
    };

    if( response.callbacks !== undefined ) {
      argilla.call(response.callbacks);
    }

    if( argilla._callbackDeferred !== null ) {
      argilla._callbackDeferred.done(function() {
        resolveAfterCallbacks();
      });
    }
    else {
      resolveAfterCallbacks();
    }
  }

  if( response.status == 'ok' &&  form !== undefined )
  {
    if( response.validateErrors !== undefined )
    {
      argilla.dom.showYiiValidateErrors(form, $.parseJSON(response.validateErrors));

      form.data("settings").submitting = false;
      if( response.scrollTo !== undefined )
        argilla.dom.scrollTo(response.scrollTo.to, response.scrollTo.time, response.scrollTo.topMargin);
    }

    if( response.messageForm !== undefined )
    {
      form.html('<div id=\'form-success-message\'>' + response.messageForm + '</div>');

      if( response.scrollOnMessage === true )
        argilla.dom.scrollTo('#form-success-message');
    }
  }

  $.mouseLoader(false);
};

argilla.startTimer =  function(timerIndex, callback, delay) {
  argilla.stopTimer(timerIndex);
  argilla.message('Запущен таймер ' + timerIndex);
  argilla.timers[timerIndex] = setTimeout(callback, delay);
};

argilla.stopTimer = function(timerIndex) {
  if(argilla.timers[timerIndex] !== undefined) {
    argilla.message('Остановлен таймер ' + timerIndex);
    clearTimeout(argilla.timers[timerIndex]);
    delete argilla.timers[timerIndex];
  }
};

argilla.generateUid = function (i) {
  return function () {
    return 'ag-uid-' + (++i);
  };
}(0);

argilla.plural = function (number, titles) {
  cases = [2, 0, 1, 1, 1, 2];
  return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
};

argilla.inArray = function (value, array) {
  for(var i=0; i<array.length; i++) {
    if(value == array[i]) return true;
  }

  return false;
};
